import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AlertController, IonicModule } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { Profile } from '../../../models/Profile';
import { Permission, PermissionsCodes } from '../../../models/Permission';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, RouterModule]
})
export class MenuPage implements OnInit {

  profile?: Profile;
  environmentName: string = "";
  version: string = "";
  cssVariables: string = "";

  sections: Section[] = [
    {
      icon: "administration",
      name: "Administración",
      pages: [
        //TODO: hay que mover esta pantalla a una APP administrativa
        { title: 'Cargas', url: '/uploads', icon: 'cloud-upload-outline', permissions: () => (this.profile?.user?.email?.endsWith('@mentesis.com') && !!this.profile?.claims?.sp ? [PermissionsCodes.LoadCabys, PermissionsCodes.LoadTerritories, PermissionsCodes.LoadEconomicActivities, PermissionsCodes.LoadProducts, PermissionsCodes.LoadDigitalDocuments, PermissionsCodes.LoadPurchaseOrders] : []), hidded: true },
        // { title: 'Indicadores', url: '/dashboard', icon: 'stats-chart-outline', hidded: true, permissions: true },
        { title: 'Compañías', url: '/companies', icon: 'business-outline', permissions: [PermissionsCodes.ViewCompanies, PermissionsCodes.EditCompanies, PermissionsCodes.CreateCompanies, PermissionsCodes.DisableCompanies], hidded: true },
        { title: 'Sucursales', url: '/branches', icon: 'storefront-outline', permissions: [PermissionsCodes.ViewBranches, PermissionsCodes.EditBranches, PermissionsCodes.CreateBranches, PermissionsCodes.DisableBranches], hidded: true },
        { title: 'Cajas', url: '/cash-desks', icon: 'tv-outline', permissions: () => (this.profile!.company.configuration.digitalInvoice.isEnable ? [PermissionsCodes.ViewCashDesk, PermissionsCodes.EditCashDesk, PermissionsCodes.EditCashDeskConsecutives, PermissionsCodes.CreateCashDesk, PermissionsCodes.DisableCashDesk, ...(this.profile!.company.configuration.cashDeskBalance.isEnable ? [PermissionsCodes.ViewCashDeskBalances, PermissionsCodes.AdjustCashDeskBalances] : [])] : []), hidded: true },
        { title: 'Usuarios', url: '/users', icon: 'people-circle-outline', permissions: [PermissionsCodes.ViewUsers, PermissionsCodes.EditUsers, PermissionsCodes.CreateUsers, PermissionsCodes.DisableUsers], hidded: true },
        { title: 'Roles', url: '/roles', icon: 'shield-checkmark-outline', permissions: [PermissionsCodes.ViewRoles, PermissionsCodes.EditRoles, PermissionsCodes.CreateRoles, PermissionsCodes.DisableRoles], hidded: true },
        { title: 'Bancos', url: '/banks', icon: 'card-outline', permissions: () => (this.profile!.company.configuration.digitalInvoice.isEnable ? [PermissionsCodes.ViewBanks, PermissionsCodes.EditBanks, PermissionsCodes.CreateBanks, PermissionsCodes.DisableBanks] : []), hidded: true },
      ],
      hidded: true
    },
    {
      icon: "purchasing",
      name: "Compras",
      pages: [
        { title: 'Proveedores', url: '/suppliers', icon: 'albums-outline', permissions: () => (this.profile!.company.configuration.purchase.isEnable ? [PermissionsCodes.ViewSuppliers, PermissionsCodes.EditSuppliers, PermissionsCodes.CreateSuppliers, PermissionsCodes.DisableSuppliers] : []), hidded: true },
        { title: 'Ordenes de Compra', url: '/purchases', icon: 'file-tray-full-outline', permissions: () => (this.profile!.company.configuration.purchase.isEnable ? [PermissionsCodes.ViewPurchases, PermissionsCodes.EditPurchases, PermissionsCodes.CreatePurchases, PermissionsCodes.CancelMaintenances] : []), hidded: true },
      ],
      hidded: true
    },
    {
      icon: "sales",
      name: "Ventas",
      pages: [
        { title: 'Clientes', url: '/clients', icon: 'people-outline', permissions: () => (this.profile!.company.configuration.digitalInvoice.isEnable || this.profile!.company.configuration.maintenance.isEnable ? [PermissionsCodes.ViewClients, PermissionsCodes.EditClients, PermissionsCodes.CreateClients, PermissionsCodes.DisableClients] : []), hidded: true },
        // { title: 'Proformas', url: '/sales', icon: 'clipboard-outline', hidded: true, permissions: true },
        { title: 'Documentos Electrónicos', url: '/invoices', icon: 'receipt-outline', permissions: () => (this.profile!.company.configuration.digitalInvoice.isEnable ? [PermissionsCodes.CreateInvoices, PermissionsCodes.CreateTickets, PermissionsCodes.CreateCreditNote, PermissionsCodes.CreateDebitNote, PermissionsCodes.ViewDigitalDocuments] : []), hidded: true },
        { title: 'Ordenes de Mantenimiento', url: '/maintenances', icon: 'construct-outline', permissions: () => (this.profile!.company.configuration.maintenance.isEnable ? [PermissionsCodes.ViewWorkshopMaintenances, PermissionsCodes.CreateWorkshopMaintenances, PermissionsCodes.InProcessMaintenance, PermissionsCodes.CompleteWorkshopMaintenances, PermissionsCodes.CloseMaintenances, PermissionsCodes.CancelMaintenances, PermissionsCodes.ViewOnSiteMaintenances, PermissionsCodes.CreateOnSiteMaintenances, PermissionsCodes.CompleteOnSiteMaintenances] : []), hidded: true },
        // { title: 'Oficina', url: '/backoffice', icon: 'business-outline', hidded: true, permissions: true },
      ],
      hidded: true
    },
    {
      icon: "store",
      name: "Almacen",
      pages: [
        //TODO: categorias no deberia estar ligada a factura electronica, debe ser de ua seccion aparte llamada categorizacion o algo asi
        { title: 'Categorias', url: '/categories', icon: 'copy-outline', permissions: () => (this.profile!.company.configuration.digitalInvoice.isEnable ? [PermissionsCodes.ViewCategories, PermissionsCodes.EditCategories, PermissionsCodes.CreateCategories, PermissionsCodes.DisableCategories] : []), hidded: true },
        { title: 'Listas de Precio', url: '/priceLists', icon: 'pricetags-outline', permissions: () => (this.profile!.company.configuration.digitalInvoice.isEnable ? [PermissionsCodes.ViewPriceLists, PermissionsCodes.EditPriceLists, PermissionsCodes.CreatePriceLists, PermissionsCodes.DisablePriceLists] : []), hidded: true },
        { title: 'Marcas', url: '/brands', icon: 'ribbon-outline', permissions: [PermissionsCodes.ViewBrands, PermissionsCodes.EditBrands, PermissionsCodes.CreateBrands, PermissionsCodes.DisableBrands], hidded: true },
        { title: 'Modelos', url: '/models', icon: 'shapes-outline', permissions: [PermissionsCodes.ViewModels, PermissionsCodes.EditModels, PermissionsCodes.CreateModels, PermissionsCodes.DisableModels], hidded: true },
        { title: 'Productos', url: '/products', icon: 'list-outline', permissions: [PermissionsCodes.ViewProducts, PermissionsCodes.EditProducts, PermissionsCodes.CreateProducts, PermissionsCodes.DisableProducts], hidded: true },
        { title: '<Equipos>', url: '/fixables', icon: 'disc-outline', permissions: () => (this.profile!.company.configuration.maintenance.isEnable ? [PermissionsCodes.ViewFixables, PermissionsCodes.EditFixables, PermissionsCodes.CreateFixables, PermissionsCodes.DisableFixables] : []), hidded: true },
        { title: 'Bodegas', url: '/warehouses', icon: 'cube-outline', permissions: () => (this.profile!.company.configuration.stock.isEnable ? [PermissionsCodes.ViewWarehouse, PermissionsCodes.EditWarehouse, PermissionsCodes.CreateWarehouse, PermissionsCodes.DisableWarehouse] : []), hidded: true },
        { title: 'Traslados de Inventario', url: '/stock-transfers', icon: 'shuffle-outline', permissions: () => (this.profile!.company.configuration.stock.isEnable ? [PermissionsCodes.ViewStockTransfers, PermissionsCodes.CreateStockTransfers, PermissionsCodes.RejectStockTransfers, PermissionsCodes.CloseStockTransfers, PermissionsCodes.CancelStockTransfers] : []), hidded: true },
        { title: 'Movimientos de Inventario', url: '/stock-changes', icon: 'swap-horizontal-outline', permissions: () => (this.profile!.company.configuration.stock.isEnable ? [PermissionsCodes.ViewStockChanges, PermissionsCodes.CreateStockChanges] : []), hidded: true },
      ],
      hidded: true
    },
  ];

  currentSection: string = "";

  constructor(
    public authService: AuthService,
    private alertController: AlertController,
    private router: Router,
    private location: Location) {
    this.version = environment.version;
    if (!environment.production) {
      this.environmentName = environment.name;
    }
  }

  async ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.expandAccordion(event.url);
      }
    });

    this.authService.profile.subscribe(profile => {
      this.profile = profile;

      if (profile?.user.isActive === false) {
        this.navigate("/home");
      }

      if (profile?.company.configuration.maintenance.isEnable) {
        //Actualiza el nombre de la pantalla de equipos

        const fixablesPage = this.sections.find(s => s.name === "Almacen")?.pages.find(p => p.url === '/fixables');

        if (fixablesPage) {
          fixablesPage.title = profile?.company.configuration.maintenance.labels.plural;
          fixablesPage.icon = profile?.company.configuration.maintenance.labels.icon;
        }
      }

      this.checkPermissions();
    })
  }

  expandAccordion(pageUrl: string) {
    for (const section of this.sections) {
      for (const page of section.pages) {
        if (pageUrl.toLowerCase().startsWith(page.url.toLowerCase())) {
          setTimeout(() => {
            this.currentSection = section.name;
          }, 10);
        }
      }
    }
  }

  async checkPermissions() {
    for (const section of this.sections) {
      let hidded = 0;
      for (const page of section.pages) {
        let permissions: PermissionsCodes[] | boolean;

        if (typeof (page.permissions) == "function") {
          permissions = page.permissions();
        }
        else {
          permissions = page.permissions;
        }

        page.hidded = false;

        if (typeof (permissions) === "boolean") {
          if (!permissions) {
            page.hidded = true;
            hidded++;
          }

          continue;
        }

        if (page.permissions && !await this.authService.hasAccess(permissions)) {
          page.hidded = true;
          hidded++;
        }
      }

      if (hidded === section.pages.length) {
        section.hidded = true;
      }
      else {
        section.hidded = false;
      }
    }

    this.updateCssVariables();
  }

  @HostListener('window:resize')
  private updateCssVariables() {
    this.cssVariables =
      `--viewport-height:${window.innerHeight}px;
       --group-count:${this.sections.filter(s => !s.hidded).length + 1};`; //+1 porque Inicio siempre esta activo
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  async logout() {
    const alert = await this.alertController.create({
      header: "Cerrar Sesión",
      message: "¿Esta seguro que desea salir del sistema?",
      buttons: [{
        text: 'Cancelar',
        role: 'cancel'
      },
      {
        text: 'Salir',
        role: 'confirm',
        handler: data => {
          this.authService.logout().then(() => {
            this.router.navigateByUrl('/login', { replaceUrl: true })
          });
        },
      }]
    });

    alert.present();
  }
}


type Section = {
  icon: string,
  name: string,
  pages: Page[]
  hidded: boolean
}

type Page = {
  title: string,
  url: string,
  icon: string,
  permissions: boolean | PermissionsCodes[] | (() => PermissionsCodes[] | boolean)
  hidded: boolean
}

// type Validation = PermissionsCodes | (() => boolean);