import { ListDocument, NamedDocument } from "./BaseDocument";

export type PriceList = ListDocument & NamedDocument & {
    profitType: ProfitType;
    profitPercentage: number;
    includeTaxInPrice: boolean
    priceAdjustment: PriceAjustment
  };
  
  export type PriceAjustment = {
    isEnabled: boolean,
    type: AdjustmentType,
    beforeTax: boolean,
    factor: number
  }

  export enum ProfitType{
    Manual = "manual",
    OnPurchase = "onpurchase",
    OnSale = "onsale",
  }
  
  export enum AdjustmentType{
    Ceil = "ceil",
    Round = "round",
    Floor = "floor"
  }
  
  export const GetText = (type: ProfitType) => {
    if(type == ProfitType.Manual){
      return "Manual";
    }

    if(type == ProfitType.OnSale){
      return "Sobre Venta";
    }
  
    if(type == ProfitType.OnPurchase){
      return "Sobre Compra";
    }
  
    throw new Error(`${type} no es un valor conocido para ProfitType`);
  }