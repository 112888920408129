
import { CanDeactivateFn } from '@angular/router';
import { StandardListPage } from '../pages/base/standard-list.page';
import { ListDocument } from 'models/BaseDocument';

export const DirtyFormGuard: CanDeactivateFn<StandardListPage<ListDocument>> = (page: StandardListPage<ListDocument>): Promise<boolean> | boolean => {
    if (!page.form) {
        return true;
    }

    return page.form.canClose(undefined);
};