import { Injectable } from '@angular/core';
import { Auth, User as UserIdentity, ParsedToken, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword, sendPasswordResetEmail } from '@angular/fire/auth';
import { DocumentData, Firestore, Timestamp, collection, collectionData, doc, docData, documentId, query, setDoc, where } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription, filter, first, firstValueFrom, map, of, switchMap } from 'rxjs';
import { ImageStorageService } from './image-storage.service';
import { Profile } from '../../../models/Profile';
import { Credentials } from '../../../models/Credentials';
import { User } from '../../../models/User';
import { Company } from '../../../models/Company';
import { isBase64Image } from '../utilities/utililties';
import { PermissionsCodes } from '../../../models/Permission';
import { CompanyBranch } from 'models/CompanyBranch';
import { Warehouse } from 'models/Warehouse';
import { Currency } from 'models/Currency';
import { AlertController } from '@ionic/angular';
import { Route, Router } from '@angular/router';
import { TipoIdentificacion } from 'models/facturaelectronica/TipoIdentificacion';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private LastCompanyKey = "DICE__LAST__COMPANY__UUID";

  private _profile: BehaviorSubject<Profile | undefined> = new BehaviorSubject<Profile | undefined>(undefined);

  private currentClaims?: ParsedToken;
  private currentUser?: User & { branchIds: string[] };
  private currentCompany?: Company & { currency: Currency };

  private userSubscription?: Subscription;
  private companySubscription?: Subscription;

  constructor(
    private auth: Auth,
    private store: Firestore,
    private imageStorage: ImageStorageService,
    private alertController: AlertController,
    private router: Router
  ) {

    this.auth.onAuthStateChanged(userIdentity => {
      console.log('onAuthStateChanged');

      this.onAuthStateChanged(userIdentity);
    })
  }

  private async onAuthStateChanged(userIdentity: UserIdentity | null) {
    if (userIdentity) {
      this.currentClaims = (await userIdentity.getIdTokenResult()).claims;
      console.log(this.currentClaims);
      console.log('size Claims', `${JSON.stringify({ companies: this.currentClaims['companies'], sp: this.currentClaims['sp'] })}`.length);

      let companyId: string = "";

      if (this.currentClaims && this.currentClaims['companies']) {
        const companies = Object.keys(this.currentClaims['companies']).filter(company => (this.currentClaims!['companies'] as any)[company] !== false);
        const lastCompanyId = localStorage.getItem(`${this.LastCompanyKey}__${userIdentity.uid}`);

        if (lastCompanyId && companies.includes(lastCompanyId)) {
          companyId = lastCompanyId;
        }
        else if (companies.length > 0) {
          companyId = companies[0];
        }
      }

      if (companyId) {
        if (this.currentUser?.uid !== userIdentity.uid || this.currentCompany?.uid !== companyId) {
          console.log('User Changed');
          this.watchUser(userIdentity.uid, companyId)
        }

        if (this.currentCompany?.uid !== companyId) {
          console.log('Company Changed');
          this.watchCompany(companyId);
        }
      }
      else {
        console.log("No Company");

        this.currentUser = {
          uid: "SinCompañia",
          image: null,
          email: userIdentity.email ?? "[Sin Correo]",
          name: userIdentity.displayName ?? "[Sin nombre]",
          jobTitle: "",
          roles: {},
          permissions: {},
          warehouses: [],
          cashdesks: [],
          branchIds: [],
          lastClaimsUpdate: Timestamp.now(),
          createdBy: "SinCompañia",
          createdDate: Timestamp.now(),
          modifiedBy: "SinCompañia",
          modifiedDate: Timestamp.now(),
          isActive: false
        }

        this.currentCompany = {
          uid: "SinCompañia",
          owner: "",
          identificationType: TipoIdentificacion.CedulaFisica,
          identification: 0,
          image: null,
          name: "SinCompañia",
          tradeName: "",
          createdBy: "SinCompañia",
          createdDate: Timestamp.now(),
          modifiedBy: "SinCompañia",
          modifiedDate: Timestamp.now(),
          isActive: false,

          currency: {} as any,
          configuration: {
            maintenance: {
              isEnabled: false
            },
            stock: {
              isEnabled: false
            },
            digitalInvoice: {
              isEnabled: false
            },
            cashDeskBalance: {
              isEnabled: false,
            },
          } as any
        }

        this.emit();
      }
    }
    else {
      console.log('Clear Auth');

      this.userSubscription?.unsubscribe();
      this.companySubscription?.unsubscribe();

      this.currentClaims = undefined;
      this.currentUser = undefined;
      this.currentCompany = undefined;

      this.router.navigateByUrl("\login");
    }
  }

  public async refreshCurrentAuthState() {
    console.log("refreshCurrentAuthState");

    await this.onAuthStateChanged(this.auth.currentUser);
  }

  private watchUser(uid: string, companyId: string) {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    let userRef;
    const support: {c: string, o: string, w: boolean, r: boolean} | undefined = this.currentClaims?.['sp'] as any;

    if (support?.o){
      //TODO: cambiar por el user service
      //Si es soporte el usuario se toma de la compañia original, no de la que se le da soporte
      userRef = doc(this.store, `companies/${support.o}/users/${uid}`);
    }
    else{
      //TODO: cambiar por el user service
      userRef = doc(this.store, `companies/${companyId}/users/${uid}`);
    }

    this.userSubscription = docData(userRef, { idField: 'uid' })
      .pipe(
        switchMap(data => {
          const user = data as User;

          if (!user.warehouses || user.warehouses.length == 0) {
            return of({ user, branchIds: [] })
          }

          const authorizedWarehousesIds = user.warehouses.map(w => w.uid);

          const warehousesRef = collection(this.store, `companies/${companyId}/warehouses`);

          const filtered = query(warehousesRef, where(documentId(), "in", authorizedWarehousesIds))

          return collectionData(filtered, { idField: 'uid' })
            .pipe(
              first(),
              map(data => {
                const warehouses = data as Warehouse[];

                const uniqueBranchIds = Array.from(new Set(warehouses.map(w => w.branchId)));

                return { user, branchIds: uniqueBranchIds }
              })
            )
        })
      )
      .subscribe(data => {
        this.currentUser = { ...data.user, branchIds: data.branchIds };

        console.log('User Updated', this.currentUser.name);

        this.emit();
      });
  }

  private async checkPermissionSync(): Promise<void> {
    console.log('checkPermissionSync');

    return new Promise((resolve, reject) => {

      if (this.currentUser && this.currentClaims && this.currentCompany) {
        if (!this.currentUser.isActive) {
          //Si el usuario esta inactivo el company debería borrarse de los claims
          if ((this.currentClaims['companies'] as any)[this.currentCompany.uid] !== false && this.currentCompany.uid !== "SinCompañia") {
            console.warn(`Permission sync issue User INACTIVE`,);

            setTimeout(() => {
              this.refreshClaims().then(() => {
                this.checkPermissionSync().then(resolve);
              })
            }, 1000);
          }

          resolve();
          return;
        }

        const support: { c: string, r: boolean, w: boolean } = this.currentClaims['sp'] as any;

        if (!(this.currentClaims['companies'] as any)[this.currentCompany.uid] && support.c === this.currentCompany.uid) {
          resolve();
          return;
        }

        const lastUserClaimsUpdate: Timestamp | undefined = this.currentUser.lastClaimsUpdate;
        let lastTokenClaimsUpdate: any | undefined = (this.currentClaims['companies'] as any)[this.currentCompany.uid]["at"];

        // console.log(lastUserClaimsUpdate, lastTokenClaimsUpdate);

        if (lastTokenClaimsUpdate) {
          lastTokenClaimsUpdate = new Timestamp(lastTokenClaimsUpdate['_seconds'], lastTokenClaimsUpdate['_nanoseconds']);
        }

        if (!(lastUserClaimsUpdate === undefined && lastTokenClaimsUpdate === undefined)
          && (lastUserClaimsUpdate === undefined
            || lastTokenClaimsUpdate === undefined
            || !lastUserClaimsUpdate.isEqual(lastTokenClaimsUpdate)
          )) {

          console.warn(`Permission sync issue User: ${lastUserClaimsUpdate?.toDate()?.toString()} Claims:${lastTokenClaimsUpdate?.toDate()?.toString()}`);

          setTimeout(() => {
            this.refreshClaims().then(() => {
              this.checkPermissionSync().then(resolve);
            })
          }, 1000);
        }
        else {
          resolve();
        }
      }
      else {
        console.log('reject');
        reject("Incomplete data");
      }
    });
  }

  private watchCompany(uid: string) {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }

    const companyRef = doc(this.store, `companies/${uid}`)

    this.companySubscription = docData(companyRef, { idField: 'uid' }).subscribe(async (data: any) => {
      console.log('Company Updated', data);

      const currencyRef = doc(this.store, `currencies/${data.configuration.general.currency}`);

      const currency = await firstValueFrom(docData(currencyRef, { idField: 'uid' }));

      this.currentCompany = {
        ...data,
        currency: currency
      } as any

      this.emit();
    });
  }

  async refreshClaims() {
    console.log('refresh token');

    // await this.auth.currentUser?.getIdToken(true);

    const result = await this.auth.currentUser?.getIdTokenResult(true);

    console.log("Refreshed companies", JSON.stringify(result?.claims['companies']));
    console.log("Refreshed Support", JSON.stringify(result?.claims['sp']));

    const emit = JSON.stringify((this.currentClaims as any).companies) !== JSON.stringify(result?.claims['companies'])

    this.currentClaims = result?.claims;

    if (emit) {
      console.log("emit new Claims", this.currentClaims);
      await this.emit();
    }

    console.log('end refresh token');
  }

  //Revisa si el token tiene los permisos de la compañia recien creada
  //Si no refresca el token hasta que los tenga
  //los permisos se agregan mediante un trigger en Cloud Funtions
  public checkNewCompany(companyId: string) {
    const interval = 500; //500 ms
    const timeout = 5000; //5 segundos
    let count = 0;

    return new Promise<void>((resolve, reject) => {
      const timer = window.setInterval(() => {
        console.log("INTERVAL FIRED", Date.now());
        if (this.currentClaims && this.currentClaims['companies'] && Object.keys(this.currentClaims['companies']).includes(companyId)) {
          window.clearInterval(timer);
          console.log("New Company Found");
          return this.refreshClaims().then(() => { return resolve(); }); //Se refrescan las credenciales para asegurar que los permisos esten actualizados
        }
        else {
          if (count > (timeout / interval)) {
            window.clearInterval(timer);
            return reject("Check New Company TimeOut");
          }

          count++;
          this.refreshClaims();
        }
      }, interval);
    })
  }

  private async emit() {
    if (this.currentUser && this.currentCompany && this.currentClaims) {
      localStorage.setItem(`${this.LastCompanyKey}__${this.currentUser.uid}`, this.currentCompany.uid);

      await this.checkPermissionSync();

      const profile = {
        user: { ...this.currentUser },
        company: { ...this.currentCompany },
        claims: { ...this.currentClaims }
      }

      this._profile.next(profile);
    }
  }

  async updateImage(imageUrl: string) {
    //se usa el profile, pra asegurar que tanto usuaer como company tienen datos
    const profile = await this.profileOnce;

    //Si es base 64 significa que es nueva, las cargadas de la BD se almacenan en Firebase Store
    const base64 = isBase64Image(imageUrl);

    console.log(base64, imageUrl);

    if (!base64.isBase64) {
      throw new Error('Image Url must be innn base64 format');
    }
    const image = await this.imageStorage.save(`companies/${profile.company.uid}/users`, `${profile.user.uid}.${base64.format}`, base64.data);

    //TODO: cambiar por el user service
    const userRef = doc(this.store, `companies/${profile.company.uid}/users/${profile.user.uid}`);

    await setDoc(userRef, { image: image }, { merge: true });
  }

  async setCurrentCompany(companyId: string) {
    this.watchCompany(companyId);

    if (this.currentUser) {
      this.watchUser(this.currentUser.uid, companyId);
    }
  }

  async register({ email, password }: Credentials) {
    const user = await createUserWithEmailAndPassword(this.auth, email, password);

    return user;
  }

  async login({ email, password }: Credentials) {
    const user = await signInWithEmailAndPassword(this.auth, email, password);

    return user;
  }

  async updatePassword(password: string, newPassword: string) {
    let user = this.auth.currentUser;

    if (!user) {
      throw new Error("User is not loged");
    }

    if (!user.email) {
      throw new Error("User has not an email address");
    }

    let credential = EmailAuthProvider.credential(
      user.email,
      password
    );

    //Se dispara un error si no authentica correctamente
    await reauthenticateWithCredential(user!, credential);

    await updatePassword(user!, newPassword);
  }

  sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  async logout() {
    signOut(this.auth);
  }

  get profileOnce(): Promise<Profile> {
    const promise = new Promise<Profile>(resolver => {
      this._profile.pipe(filter(x => x !== undefined), first()).subscribe(profile => {
        //Debido al filter nunca deberia ser undefined
        resolver(profile!);
      })
    })

    return promise;
  }

  get profile(): BehaviorSubject<Profile | undefined> {
    return this._profile;
  }

  async hasAccess(permissions: PermissionsCodes[]): Promise<boolean> {
    const profile = await this.profileOnce;

    if (!profile.user.isActive) {
      return false;
    }

    if (profile.claims?.sp?.c === profile.company.uid) {
      //Si es soporte
      return true;
    }

    if (profile && profile.company && profile.claims) {
      for (const permission of permissions) {
        if (profile.claims.companies[profile.company.uid].list.includes(permission)) {
          return true;
        }
      }
    }

    return false;
  }
}