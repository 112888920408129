import { ListDocument } from "./BaseDocument";
import { ListItem } from "./ListItem";

export type MultiField = ListDocument & (TextFiled | NumberFiled | SelectFiled | ChipSelectFiled | DateTimeFiled);

export type TextFiled = BaseFiled & {
  type: FieldTypes.Text
}

export type NumberFiled = BaseFiled & {
  type: FieldTypes.Number
}

export type SelectFiled = BaseFiled & {
  type: FieldTypes.Select,
  list: (ListItem & { inDescriptionValue: string | undefined | null })[]
}

export type ChipSelectFiled = BaseFiled & {
  type: FieldTypes.ChipSelect,
  max: number | null,
  list: (ListItem & { inDescriptionValue: string | undefined | null })[]
  separator: string;
}

export type DateTimeFiled = BaseFiled & {
  type: FieldTypes.DateTime,
  max: 'today' | null,
  min: 'today' | null,
  presentation: "date" | "date-time" | "month" | "month-year" | "time" | "time-date" | "year"
}

type BaseFiled = {
  type: FieldTypes;
  label: string;
  description: string;
  isRequired: boolean;
  isUnique: boolean;
  showsScanner: boolean;
  order: number;
  size: FieldSizes,
  isIncludeInDescriptions: boolean;
}

export enum FieldTypes {
  Text = 'text',
  Number = 'number',
  Select = 'select',
  ChipSelect = "chip-select",
  DateTime = "datetime"
}

export enum FieldSizes {
  Large = 'L',
  Medium = 'M',
  Small = 'S',
}

export const multifieldDescription = (document: any, fields: MultiField[] = [], separator: string = " "): string => {
  let result = "";

  for (const field of fields.sort((a, b) => (a.order - b.order))) {
    if (!field.isActive) {
      continue;
    }
    if (!field.isIncludeInDescriptions) {
      continue;
    }

    if (result !== "") {
      result += separator; //agrega el separador
    }

    switch (field.type) {
      case FieldTypes.Text:
      case FieldTypes.Number:
        result += document[field.uid]?.toString() ?? "";
        break;

      case FieldTypes.Select:
        const value = document[field.uid]?.toString() ?? "";

        const item = field.list.find(f => f.uid === value);

        if (!item) {
          console.warn(`The value '${value}' was not found on the list ${field.label} [${field.uid}]`);
          result += "[Error: NotFound]"
        }
        else {
          result += item?.inDescriptionValue ?? item?.name ?? item?.description ?? item?.code ?? item?.uid;
        }
        break;

      case FieldTypes.ChipSelect:
        result += (document[field.uid] ?? [])
          .sort((a:string,b:string) => {
            const indexA = field.list.findIndex(f => f.uid === a);
            const indexB = field.list.findIndex(f => f.uid === b);
            return indexA - indexB;
          })
          .map((value: string) => {
            const item = field.list.find(f => f.uid === value);

            if (!item) {
              console.warn(`The value '${value}' was not found on the list ${field.label} [${field.uid}]`);
              return "[Error: NotFound]"
            }

            return item?.inDescriptionValue ?? item?.name ?? item?.description ?? item?.code ?? item?.uid;
          })
          .join(field.separator);
        break;

      default:
        console.warn(`FieldType was not found`);
        break;
    }
  }

  return result;
}