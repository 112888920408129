import { environment } from "src/environments/environment";
import { AdjustmentType, PriceList, ProfitType } from "../../../models/PriceList";
import { FieldValue, Timestamp, serverTimestamp } from "@angular/fire/firestore";
import { PermissionsCodes } from "models/Permission";
import { Analytics, logEvent } from "@angular/fire/analytics";
import { ListItem } from "models/ListItem";

export const calculatePrice = (priceList: PriceList, cost: number, tax: number): number => {
    let calculatedPrice: number;

    switch (priceList.profitType) {
        case ProfitType.Manual:
            return NaN;
        case ProfitType.OnPurchase:
            calculatedPrice = cost * (1 + (priceList.profitPercentage / 100));
            break;
        case ProfitType.OnSale:
            calculatedPrice = cost / (1 - (priceList.profitPercentage / 100));
            break;
        default:
            throw new Error(`${priceList.profitType} no es un valor conocido para ProfitType`);
    }

    if (priceList.priceAdjustment.isEnabled) {
        let adjustmentFunction: Function;

        switch (priceList.priceAdjustment.type) {
            case AdjustmentType.Ceil:
                adjustmentFunction = Math.ceil;
                break;
            case AdjustmentType.Round:
                adjustmentFunction = Math.round;
                break;
            case AdjustmentType.Floor:
                adjustmentFunction = Math.floor;
                break;
            default:
                throw new Error(`${priceList.priceAdjustment.type} no es un valor conocido para AjustmentType`)
        }

        if (priceList.priceAdjustment.beforeTax) {
            calculatedPrice = adjustmentFunction(calculatedPrice / priceList.priceAdjustment.factor) * priceList.priceAdjustment.factor;
        }
        else {
            let totalPrice = calculatedPrice * (1 + tax);

            totalPrice = adjustmentFunction(totalPrice / priceList.priceAdjustment.factor) * priceList.priceAdjustment.factor;
            calculatedPrice = totalPrice / (1 + tax);
        }
    }

    return calculatedPrice;
}

export const toIsoString = (date: Date): string => {
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num: number) => {
        return (num < 10 ? '0' : '') + num;
    };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}

export const removeEmpty = (obj: any): any => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value === undefined) {
                delete obj[key];
            } else if (typeof value === 'object') {
                removeEmpty(value);
            }
        }
    }
}

export const trimProperties = (obj: any): any => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if ((typeof value) === 'string') {
                obj[key] = obj[key].trim();
            } else if (typeof value === 'object') {
                trimProperties(value);
            }
        }
    }
}


export const isBase64Image = (url: string): { isBase64: boolean, format: string, data: string } => {
    // Lista de extensiones de archivos que consideramos como imágenes
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

    // Verificar si el URL comienza con 'data:image/'
    if (url.startsWith('data:image/')) {
        const [prefix, data] = url.split(';base64,');
        // Extraer el tipo de imagen desde el URL
        const format = prefix.split(':')[1].split('/')[1];
        // Verificar si el tipo de imagen es válido
        if (imageExtensions.includes(format)) {
            return { isBase64: true, format: format, data: data };
        }
    }
    else {
        // Verificar si el URL contiene una extensión de archivo
        const fileExtension = url.split('.').pop()?.toLowerCase();

        if (fileExtension && imageExtensions.includes(fileExtension)) {
            return { isBase64: true, format: fileExtension, data: "" }
        } else {
            return { isBase64: false, format: fileExtension ?? "", data: "" }
        }
    }

    return { isBase64: false, format: "", data: "" };
}

export const getBase64FromUrl = async (url: string): Promise<string> => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data as string);
        }
    });
}

export const numberFormat = new Intl.NumberFormat('en-US',
    {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });


export const restoreTimestampObjects = (obj: any): any => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value instanceof Timestamp) {
                continue;
            } else if (value && value.seconds != undefined && value.nanoseconds != undefined) {
                obj[key] = new Timestamp(value.seconds, value.nanoseconds);
                continue;
            } else if (typeof value === 'object') {
                restoreTimestampObjects(value);
            }
        }
    }
}

export const splitArray = <T>(array: T[], size: number): T[][] => {
    if (!array || array.length === 0) {
        return [[]];
    }

    const groups = [];

    for (let i = 0; i < array.length; i += size) {
        groups.push(array.slice(i, i + size));
    }
    return groups;
}

export const getImageFormat = (url: string): string | null => {
    try {
        const urlObj = new URL(url);
        const pathname = urlObj.pathname;
        const filename = pathname.split('/').pop(); // Obtiene el nombre del archivo
        if (filename) {
            const extension = filename.split('.').pop(); // Obtiene la extensión del archivo
            if (extension) {
                const formato = extension.toLowerCase();
                if (formato === 'jpg' || formato === 'jpeg') {
                    return 'JPEG';
                } else if (formato === 'png') {
                    return 'PNG';
                } else {
                    return null; // Formato de imagen desconocido
                }
            }
        }
        return null; // No se pudo determinar el formato
    } catch (error) {
        console.error('Error al obtener el formato de la imagen:', error);
        return null;
    }
}

export const isDarkColor = (color: string): boolean => {
    // Elimina el "#" si está presente en la cadena del color hexadecimal
    const hexColor = color.replace("#", "");

    // Convierte el color hexadecimal a componentes RGB
    var r = parseInt(hexColor.substring(0, 2), 16) / 255;
    var g = parseInt(hexColor.substring(2, 4), 16) / 255;
    var b = parseInt(hexColor.substring(4, 6), 16) / 255;

    // Calcula la luminancia (Y) utilizando la fórmula
    var luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    return luminance <= 0.5;
}

export const setPWAThemeColor = () => {
    const darkModeColor = '#34315d';
    const lightModeColor = '#edecf5';

    const themeColor = document.querySelector('meta[name="theme-color"]');

    if (!themeColor) {
        return;
    }

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        themeColor.setAttribute('content', darkModeColor);
    }
    else {
        themeColor.setAttribute('content', lightModeColor);
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        if (event.matches) {
            themeColor.setAttribute('content', darkModeColor);
        }
        else {
            themeColor.setAttribute('content', lightModeColor);
        };
    });
}

export const checkPermissionConsistency = () => {
    //Si esá local revisa que los Ids de los permisos no tengan errores
    if (environment.name === 'Local') {
        let isValid = true;
        const values = Object.values(PermissionsCodes).filter((v) => !isNaN(Number(v))).map(v => Number(v));

        const min = Math.min(...values);
        const max = Math.max(...values);

        for (let num = min; num <= max; num++) {
            const permission = values.filter(v => v === num);

            if (!permission || permission.length === 0) {
                console.error(`El Permiso número ${num} no existe!!!`);
                isValid = false;
                continue;
            }

            if (permission.length > 1) {
                console.error(`El Permiso número ${num} esta duplicado!!!`);
                isValid = false;
            }
        }

        if (!isValid) {
            alert("Los permisos no están bien configurados, revisa la consola para más información");
        }
    }
}

export const logAnalyticsError = (analytics: Analytics, error: any, reference: string) => {
    let parameters: { description: string, fatal: boolean, stack: any, reference: string } = {
        description: "",
        fatal: false,
        stack: undefined,
        reference: reference
    };

    if (typeof (error) === 'string') {
        parameters.description = error;
    }

    if (typeof (error) === 'object') {
        if ('message' in error) {
            parameters.description = error.message;
        }
        else if ('toString' in error) {
            parameters.description = error.toString();

            if (parameters.description.startsWith("[object")) {
                parameters.description = JSON.stringify(error);
            }

            if (parameters.description === "{}") {
                parameters.description = error;
            }
        } else {
            parameters.description = error;
        }

        if ('stack' in error) {
            parameters.stack = error.stack;
        }
    }

    logEvent(analytics, 'exception', parameters)
}

export const discountList = (max: number) => {
    const list: ListItem[] = [];

    list.push({
        uid: null,
        name: "0"
    })

    for (let i = 1; i <= 5; i++) {
        if (i <= max) {
            list.push({
                uid: i,
                name: `${i}%`
            });
        }
    }

    for (let i = 10; i <= 20; i += 5) {
        if (i <= max) {
            list.push({
                uid: i,
                name: `${i}%`
            });
        }
    }

    return list;
}

export const isEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!keys2.includes(key)) {
            return false;
        }

        if (!isEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}