import { Routes } from '@angular/router';
import { canActivate, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard'
import { DirtyFormGuard } from './utilities/DirtyFormGuard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./login/login.page').then(m => m.LoginPage),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: 'register',
    loadComponent: () => import('./register/register.page').then(m => m.RegisterPage),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./forgot-password/forgot-password.page').then( m => m.ForgotPasswordPage),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: 'profile',
    loadComponent: () => import('./profile/profile.page').then( m => m.ProfilePage),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'home',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () => import('./pages/home/home.page').then( m => m.HomePage)
  },
  {
    path: 'uploads',
    loadComponent: () => import('./pages/uploads/uploads.page').then( m => m.UploadsPage),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'companies',
    loadComponent: () => import('./pages/companies/companies.page').then( m => m.CompaniesPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'branches',
    loadComponent: () => import('./pages/companyBranches/companyBranches.page').then( m => m.CompanyBranchesPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'suppliers',
    loadComponent: () => import('./pages/suppliers/suppliers.page').then((m) => m.SuppliersPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'categories',
    loadComponent: () => import('./pages/categories/categories.page').then(m => m.CategoriesPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'priceLists',
    loadComponent: () => import('./pages/price-lists/price-lists.page').then(m => m.PriceListsPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'products',
    loadComponent: () => import('./pages/products/products.page').then(m => m.ProductsPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'clients',
    loadComponent: () => import('./pages/clients/clients.page').then(m => m.ClientsPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },  
  {
    path: 'invoices',
    loadComponent: () => import('./pages/digital-documents/digital-documents.page').then( m => m.DigitalDocumentsPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'maintenances',
    loadComponent: () => import('./pages/maintenances/maintenances.page').then( m => m.MaintenancesPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'sales',
    loadComponent: () => import('./pages/sales/sales.page').then(m => m.SalesPage),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'backoffice',
    loadComponent: () => import('./pages/backoffice/backoffice.page').then(m => m.BackofficePage),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'warehouses',
    loadComponent: () => import('./pages/warehouses/warehouses.page').then( m => m.WarehousesPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'banks',
    loadComponent: () => import('./pages/banks/banks.page').then( m => m.BanksPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'users',
    loadComponent: () => import('./pages/users/users.page').then( m => m.UsersPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'roles',
    loadComponent: () => import('./pages/roles/roles.page').then( m => m.RolesPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'cash-desks',
    loadComponent: () => import('./pages/cash-desks/cash-desks.page').then( m => m.CashDesksPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'brands',
    loadComponent: () => import('./pages/brands/brands.page').then( m => m.BrandsPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'models',
    loadComponent: () => import('./page/models/models.page').then( m => m.ModelsPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'purchases',
    loadComponent: () => import('./pages/purchases/purchases.page').then( m => m.PurchasesPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'stock-changes',
    loadComponent: () => import('./pages/stock-changes/stock-changes.page').then( m => m.StockChangesPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'stock-transfers',
    loadComponent: () => import('./pages/stock-transfers/stock-transfers.page').then( m => m.StockTransfersPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'stock-transfer-receptions',
    loadComponent: () => import('./pages/stock-transfer/subPages/stock-transfer-receptions/stock-transfer-receptions.page').then( m => m.StockTransferReceptionsPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: 'fixables',
    loadComponent: () => import('./pages/fixables/fixables.page').then( m => m.FixablesPage),
    ...canActivate(redirectUnauthorizedToLogin),
    canDeactivate:[DirtyFormGuard]
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];
